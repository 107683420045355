import {Modal} from "antd";
import {post, postApi} from '../axios';
import DB from "@Cyclope/core/store/db";

const _ = require('lodash');

const fnLogin = (dispatch, payload) => {
    post('/tapi/auth', payload, (response) => {
        if (!_.isNull(response)) {
            if (response.user.token) {

                console.log("response", response);

                let initialRoute = null;
                if (Array.isArray(response.menu)) {
                    const defaultRoutes = response.menu.filter(m => m.isDefault === true);
                    if (defaultRoutes.length > 0 && defaultRoutes[0].route) {
                        initialRoute = defaultRoutes[0].route;
                    }
                }
                const payload = {
                    token: response.user.token,
                    userName: response.user.name,
                    userRole: response.user.role,
                    menu: response.menu,
                    initialRoute
                }
                DB.save(":auth", payload);
                dispatch.state.loginReceived(payload).then(() => {
                    if (payload.initialRoute) dispatch.state.mainRedrawing(payload.initialRoute);
                    dispatch.setting.initAuth({})
                })
            }
        }
    });
}

const fnLogout = (dispatch, payload, state) => {
    const {token} = state;
    postApi(token, '/tapi/exit', {}, () => {
        DB.destroy(":auth").then(() => {
            window.location.reload();
        })
    });
}

const fnForgotPassword = (dispatch, payload, state) => {
    const {form, callback} = payload;
    post('/tapi/auth/forgot', form.name, (response) => {
        if (!_.isNull(response)) {
            if (response.message) {
                Modal.info({
                    title: response.message,
                    onOk() {},
                });
            }
            callback(true);
        } else callback(false);
    });
}

export {
    fnLogin,
    fnLogout,
    fnForgotPassword
};
