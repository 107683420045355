import {PureComponent} from "react";
import {connect} from "react-redux";
import * as signalR from "@microsoft/signalr";
import { notification } from "antd";

class SignalR extends PureComponent<any> {

    componentDidMount = () => {
        const self = this,
        withUrl = "/ws",
        {token, setSocket} = self.props,
        connection = new signalR.HubConnectionBuilder()
            .configureLogging(signalR.LogLevel.Debug)
            .withUrl(withUrl, {
                accessTokenFactory: () => token
            }).build();

        connection.on("receiveMessageOne", (response: string) => {
            if (response) {
                try {
                    const json = JSON.parse(response);
                    if (json) {
                        if (json.unread) setSocket(json);
                        if (json.unreadnotes) setSocket(json);
                        if (json.message) {
                            notification.open({
                                message: 'Уведомление',
                                description: json.message,
                                duration: 5000,
                                onClick: () => {},
                            });
                        }
                    }
                } catch (error) {
                    console.log(error, response);
                }
            }
        });

        connection.start().catch((err: any) => {
            return console.error(err.toString());
        });
    }

    render = () => {
        return null;
    }
}

const mapState = (state: any) => {
    return {
        token: state.state.token
    };
},
mapDispatch = (dispatch: any) => {
    return {
        setSocket: (data: any) => dispatch.socket.set(data),
    };
}

export default connect(mapState, mapDispatch)(SignalR);
