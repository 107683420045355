import {Modal, notification} from 'antd';
import {postApi} from '../axios';
import {container} from "../../../original/models/container";

const _ = require('lodash');

const requestAllowed = (dispatch, token, data) => {
    postApi(token, '/tapi/worker/getdata', data, (answer) => {
        let payload = [];
        console.log(answer);
        if(!_.isNull(answer)) {
            if (answer.file) {
                window.open(answer.file);
            } else if (answer.push) {
                payload.push(container(0, answer.push, answer.title));
                dispatch.state.reloadData(payload);
            } else {
                notification.error({
                    message: 'метод "/tapi/worker/getdata" не содержит push',
                    duration: 3000,
                });
            }
        }
    });
}

const fnMainRedrawing = (dispatch, payload, storage) => {
    const {token} = storage;

    let data = payload;
    if(typeof data === 'string' || data instanceof String){
        data = JSON.parse(data);
    }

    let allowed = true;
    storage.data.forEach((item) => {
        if (item.disabled) allowed = false;
    });

    if (allowed) requestAllowed(dispatch, token, data);
    else {
        Modal.confirm({
            title: 'Вы уверены что сохранили все изменения?',
            okText: 'Да',
            cancelText: 'Нет',
            onOk() {
                requestAllowed(dispatch, token, data);
            }
        })
    }
}
export default fnMainRedrawing;
