const check = (value) => {
	return JSON.stringify(value.initialValue) !== JSON.stringify(value.values)
}

const handleForm = {
	state: {},
	reducers: {
        reloadData(state, payload) {
            return {...state, ...payload}
        },
	},
	effects: dispatch => ({
        reload(payload, rootState) {
            const {id, pid, type, values} = payload;
            let state = Object.assign({}, rootState.handleForm);

            if (id in state) {
				let data = Object.assign({}, state[id]);
				data[type] = values;
                state[id] = data
            } else {
				let data = {
                    values: {},
                    initialValue: {}
                };
				data[type] = values;
                state[id] = {
                    pid: pid,
                    ...data
                }
            }
			if (payload.callback) payload.callback();
            dispatch.handleForm.reloadData(state)
        },
        confirmDestroy(pid, rootState) {
			const handleForm = Object.assign({}, rootState.handleForm);
            let response = false;

			for (const [key, value] of Object.entries(handleForm)) {
				if (value.pid === pid && check(value)) {
					response = true;
				}
			}

            return response;
        }
	}),
}

export default handleForm;
