import {isMobile} from "react-device-detect";
import {post, postApi} from "@Cyclope/core/store/axios";
import {cloneObject, parseBool, isObject} from "@Cyclope/core/store/extra";
import {IStoreSetting, EnumAppTemplate, ExitButtonTemplate} from "./index.d";

const initialState: IStoreSetting = {
    appName: "Загрузка...",
    showForgotPassword: false,
    isMobileDisplay: false,
    appTemplate: EnumAppTemplate.TitleBar,
    logoHidden: false,
    logoPath: null,
    exitButtonTemplate: ExitButtonTemplate.Short,
    ready: false
}

const builder = (setting: IStoreSetting, response: any): IStoreSetting => {
    if (response) {
        for (const [key, value] of Object.entries(setting)) {
            if (key === "isMobileDisplay") {
                if (isMobile && response[key]) setting[key] = response[key];
            } else if (response[key]) setting[key] = response[key];
        }
    }
    return setting;
}

const setting = {
	state: initialState,
	reducers: {
		update(state: any, payload: any) {
            return {...state, ...payload}
        },
    },
    effects: (dispatch: any) => ({
		init(payload: any, rootState: any) {
            post("/tapi/auth/config", null, (response: any) => {
                const setting: IStoreSetting = builder(cloneObject(initialState), response);
                setting.ready = true;
                dispatch.setting.update(setting)
            })
        },
		initAuth(payload: any, rootState: any) {
            const {state} = rootState;
            postApi(state.token, "/tapi/worker/config", null, (response: any) => {
                if (isObject(response) && Object.entries(response).length) {
                    const setting: IStoreSetting = builder(cloneObject(rootState.setting), response);
                    dispatch.setting.update(setting)
                }
            })
        },
    })
}

export default setting;
