import {findById} from './extra/functions';

const fnDestroy = (dispatch, payload, storage) => {
    let data = Object.assign([], storage.data),
        key = findById(data, payload);

    if (key !== -1) {
        let keyPid = findById(data, data[key].pid);
        if (keyPid !== -1) {
            data[keyPid].disabled = false;
            if (!data[keyPid].update) {
                data[keyPid].update = false;
                data[keyPid].loading = false;
            }
        }
        data.splice(key, 1);
        dispatch.state.reloadData(data);

        // Открываем для редактирования
        if (keyPid !== -1 && data[keyPid].update) {
            dispatch.state.updateParent(data[keyPid].id);
        }

    } else {
        console.error('Объект для удаление с id = ' + payload + ' не найден');
    }
}

export default fnDestroy;
