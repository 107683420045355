import {findById} from '../effects/extra/functions';
import fnInitApp from '../effects/fnInitApp';
import {fnLogin, fnLogout, fnForgotPassword} from '../effects/fnLogin';
import fnMainRedrawing from '../effects/fnMainRedrawing';
import fnRedrawing from '../effects/fnRedrawing';
import fnDestroy from '../effects/fnDestroy';
import fnUpdateParent, {fnUpdateParentByPid} from '../effects/fnUpdateParent';
import {fnComponentLoading, fnSetComponentLoading} from '../effects/fnComponentLoading';
import fnSetActiveTab from '../effects/fnSetActiveTab';

const initialState = {
    token:   "",
    userName: "",
    userRole: "",
    menu:    [],
    data:    [],
    initialRoute: null,
    loading: false,
    tableFormDataValuses: null,
    drawer: {
        pid: "",
        title: "",
        placement: "right",
        closable: false,
        visible: false,
        width: 500,
        dataSource: {}
    }
}

export const state = {
	state: initialState,
	reducers: {
        setLoadding(state, payload) {
            return {...state, loading: payload}
        },
        loginReceived(state, payload) {
            return {...state, ...payload}
        },
        reloadData(state, payload) {
            return {...state, data: payload}
        },
        tableFormSetValues(state, payload) {
            return {...state, tableFormDataValuses: payload}
        },
        setDrawer(state, payload) {
            return {...state, drawer: payload}
        }
	},
	effects: dispatch => ({
        initApp(payload, rootState) {
            return fnInitApp(dispatch, payload, rootState.state)
        },
        login(payload, rootState) {
            return fnLogin(dispatch, payload, rootState.state)
        },
        forgotPassword(payload, rootState) {
            return fnForgotPassword(dispatch, payload, rootState.state)
        },
        logout(payload, rootState) {
            return fnLogout(dispatch, payload, rootState.state)
        },
        mainRedrawing(payload, rootState) {
            return fnMainRedrawing(dispatch, payload, rootState.state)
        },
        redrawing(payload, rootState) {
            return fnRedrawing(dispatch, payload, rootState.state)
        },
        destroy(payload, rootState) {
            return fnDestroy(dispatch, payload, rootState.state)
        },
        updateParent(payload, rootState) {
            return fnUpdateParent(dispatch, payload, rootState.state)
        },
        updateParentByPid(payload, rootState) {
            return fnUpdateParentByPid(dispatch, payload, rootState.state)
        },
        componentLoading(payload, rootState) {
            return fnComponentLoading(dispatch, payload, rootState.state)
        },
        setComponentLoading(payload, rootState) {
            return fnSetComponentLoading(dispatch, payload, rootState.state)
        },
        setActiveTab(payload, rootState) {
            return fnSetActiveTab(dispatch, payload, rootState.state)
        },
        tableFormGetValues(payload, rootState) {
            return rootState.state.tableFormDataValuses;
        },
        closeDrawer(payload, rootState) {
            let drawer = Object.assign({}, rootState.state.drawer);
            const pid = drawer.pid;

            drawer.pid = "";
            drawer.title = "";
            drawer.dataSource = {};
            drawer.visible = false;
            const promise = dispatch.state.setDrawer(drawer);
            promise.then(() => {
                let data = Object.assign([], rootState.state.data),
                    keyPid = findById(data, pid);

                if (keyPid !== -1) {
                    dispatch.state.updateParent(data[keyPid].id);
                }
            });
        }
	}),
}
