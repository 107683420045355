const _ = require('lodash');

export const findById = (array, id) => {
    return _.findIndex(array, function(o) { return o.id === id})
}

// export const findProperty = (obj, propContentPart, path="", maps=[]) => {
//     var val;
//     if (_.isObject(obj)) {
//         Object.keys(obj).forEach((key, index) => {
//             var value = obj[key];

//             if (_.isObject(value)) {

//                 if (key === propContentPart) {
//                     val=(path==='')? key: path;
//                     return maps.push(val);
//                 }

//                 val=(path==='')? key: path+'.'+key;
//                 findProperty(value, propContentPart, val, maps);
//             }
//         });
//     } else {
//         console.warn("функция findProperty Ожидал object пришел", obj);
//     }
//     return maps;
// };

export const findProperty = (obj, propContentPart, path="", maps=[], maximumDescent = 30) => {
    var val;
    try {
        if (_.isObject(obj)) {
            Object.keys(obj).forEach((key, index) => {
                var value = obj[key];
    
                if (_.isObject(value)) {
    
                    if (key === propContentPart) {
                        val=(path==='')? key: path;
                        return maps.push(val);
                    }
    
                    val=(path==='')? key: path+'.'+key;

                    maximumDescent -= 1;

                    if (maximumDescent > 0) findProperty(value, propContentPart, val, maps, maximumDescent);
                }
            });
        } else {
            console.warn("функция findProperty Ожидал object пришел", obj);
        }
    } catch (error) {
        console.log(error);
    }
    return maps;
};

export const twoObjectsAreEqual = (objectOne, objectTwo) => {
    try {
        return JSON.stringify(objectOne) === JSON.stringify(objectTwo);
    } catch (e) {
        return false;
    }
}

export const cloneObject = (ovject) => {
    var clone = JSON.stringify(ovject);
    return JSON.parse(clone);
};
