import React from 'react';
import ReactDOM from 'react-dom';
import App from '@Cyclope/App';
import {initializeIcons} from "@fluentui/react/lib/Icons";
import * as serviceWorker from './serviceWorker';

initializeIcons();

ReactDOM.render(
    <App />,
    document.getElementById('root')
);

serviceWorker.unregister();
