import React from 'react';
import {message, Modal, notification} from 'antd';

import {postGetData} from '../axios';
import {findById, findProperty} from './extra/functions';
import {container, containerDraw} from "../../../original/models/container";
import {isFunction} from "../../../original/helpers/functions";

const _ = require('lodash');
const clone_object = (ovject) => {
    var clone = JSON.stringify(ovject);
    return JSON.parse(clone);
};

const generateUID = obj => {
    try {
        let str = [];
        for (const [p, val] of Object.entries(obj)) {
            str.push(`${p}::${val}`);
        }
        return str.join("-");
    } catch (error) {
        return "";
    }
}

const redrawingFile = (token, response) => {
    if (_.isObject(response.file) && response.file.id && response.file.mime && response.file.action) {
        var request =  new XMLHttpRequest();
        request.open("GET", response.file.action + "?id=" + response.file.id);
        request.setRequestHeader("Authorization", "Bearer " + token);
        request.responseType = "arraybuffer";
        request.onload = (e) => {
            if (request.status == 200 && request.response) {
                var arrayBufferView = new Uint8Array(request.response);
                var file = new Blob([arrayBufferView], {type: response.file.mime});
                var urlCreator = window.URL || window.webkitURL;
                var fileurl = urlCreator.createObjectURL(file);

                let tempLink = document.createElement('a');
                tempLink.href = fileurl;
                tempLink.setAttribute('download', response.file.name?response.file.name:'filename');
                tempLink.click();

                urlCreator.revokeObjectURL(fileurl);
            } else {
                message.error("Ошибка сервера №" + request.status);
            }
        };
        request.send();
    } else {
        window.open(response.file);
    }
}

const redrawingMessage = (dispatch, payload, response) => {
    if (typeof Modal[!_.isUndefined(response.message.type)?response.message.type:"info"] === 'function') {
        let content = "";
        if (!_.isUndefined(response.message.text)) {
            content = response.message.text;
            if (_.isObject(response.message.text)) {
                content = Object.keys(response.message.text).map(function (key, index) {
                    return <p key={index} className="mb-0">{response.message.text[key]}</p>;
                });
            }
        }
        let modalType = Modal[response.message.type];
        if (response.message.onOk && response.message.onCancel) {
            modalType = Modal.confirm;
        }
        modalType({
            title: !_.isUndefined(response.message.title)?response.message.title:"",
            content: content,
            okText: !_.isUndefined(response.message.okText)?response.message.okText:"Да",
            cancelText: !_.isUndefined(response.message.cancelText)?response.message.cancelText:"Отмена",
            onOk() {
                if (!_.isUndefined(response.message.onOk) && !_.isNull(response.message.onOk)) {
                    let action = clone_object(payload);
                    action.action = response.message.onOk;
                    dispatch.state.redrawing(action);
                }
            },
            onCancel() {
                if (!_.isUndefined(response.message.onCancel) && !_.isNull(response.message.onCancel)) {
                    let action = clone_object(payload);
                    action.action = response.message.onCancel;
                    dispatch.state.redrawing(action);
                }
            }
        });
    } else {
        notification.error({
            message: 'Сообщение должно иметь тип: info, success, error, warning, confirm',
            description: 'Пришло ' + response.message.type,
            duration: 3000,
        });
    }
}

// const redrawingUpdate = (response, key, data) => {
//     if (response.title && response.title.trim() !== "") {
//         data[key].title = response.title;
//     }

//     Object.keys(response.update).forEach((objectKey, index) => {
//         if (!Array.isArray(data[key].data) && data[key].data[objectKey]) {
//             data[key].data[objectKey] = response.update[objectKey];
//         } else {
//             let paths = findProperty(data[key].data, objectKey);
//             if (paths.length) {
//                 let beenChanged = false;

//                 paths.forEach((path) => {
//                     var elem = data[key].data;
//                     var pathSplit = path.split('.');
//                     for (var i=0, len=pathSplit.length; i<len; i++){
//                         elem = elem[pathSplit[i]];
//                     };
//                     if (
//                         elem[objectKey]
//                         // && !_.isUndefined(elem[objectKey].action.model)
//                         // && !_.isUndefined(response.update[objectKey].action.model)
//                         // && response.update[objectKey].action.model === elem[objectKey].action.model
//                     ) {
//                         elem[objectKey] = response.update[objectKey];
//                         beenChanged = true;
//                     }
//                 });
//                 if (!beenChanged) {
//                     notification.error({
//                         message: 'в текущем state и его потомках не найден компонент "' + objectKey + '"',
//                         description: 'Проверяйте код',
//                         duration: 3000,
//                     });
//                 }
//             } else {
//                 notification.error({
//                     message: 'В state нет компонента "' + objectKey + '"',
//                     description: 'Есть только ' + Object.keys(data[key].data),
//                     duration: 3000,
//                 });
//             }
//         }
//     });
//     var keyPid = findById(data, data[key].pid);
//     if (keyPid !== -1) {
//         data[keyPid].update = true;
//         data[keyPid].loading = true;
//     }

//     return data;
// }

const redrawingUpdate = (response, key, data) => {
    if (response.title && response.title.trim() !== "") {
        data[key].title = response.title;
    }

    const updateingObject = (dataSource) => {
        Object.keys(dataSource).forEach((objectKey, index) => {
            if (!Array.isArray(data[key].data) && data[key].data[objectKey]) {
                data[key].data[objectKey] = dataSource[objectKey];
            } else {
                const paths = findProperty(data[key].data, objectKey);

                if (paths.length) {
                    let beenChanged = false;
                    paths.forEach((path) => {
                        var parentElement = null;
                        var elem = data[key].data;
                        var pathSplit = path.split('.');
                        for (var i=0, len=pathSplit.length; i<len; i++){
                            elem = elem[pathSplit[i]];
                            if (i < len - 1) parentElement = elem;
                        };
                        if (elem[objectKey]) {
                            if (elem[objectKey].action && dataSource[objectKey].action) {
                                if (
                                    !_.isUndefined(elem[objectKey].action.model)
                                    && !_.isUndefined(dataSource[objectKey].action.model)
                                    && dataSource[objectKey].action.model === elem[objectKey].action.model
                                ) {
                                    elem[objectKey] = dataSource[objectKey];
                                    beenChanged = true;
                                    // Костыль #2
                                    if (
                                        parentElement &&
                                        parentElement.update !== undefined &&
                                        parentElement.update.model !== undefined &&
                                        elem[objectKey].update !==  undefined &&
                                        elem[objectKey].update.model !==  undefined 
                                    ) {
                                        parentElement.update = elem[objectKey].update
                                    }
                                    
                                }
                            } else if (elem[objectKey].update && dataSource[objectKey].update) {
                                // if (
                                //     !_.isUndefined(elem[objectKey].update.model)
                                //     && !_.isUndefined(dataSource[objectKey].update.model)
                                //     && dataSource[objectKey].update.model === elem[objectKey].update.model
                                // ) {
                                //     elem[objectKey] = dataSource[objectKey];
                                //     beenChanged = true;
                                // }
                                
                                if (
                                    !_.isUndefined(elem[objectKey].update.model)
                                    && !_.isUndefined(dataSource[objectKey].update.model)
                                    && dataSource[objectKey].update.model === elem[objectKey].update.model
                                    && generateUID(dataSource[objectKey].update.data) === generateUID(elem[objectKey].update.data)
                                ) {
                                    elem[objectKey] = dataSource[objectKey];
                                    beenChanged = true;
                                    // Костыль #2
                                    if (
                                        parentElement &&
                                        parentElement.update !== undefined &&
                                        parentElement.update.model !== undefined &&
                                        elem[objectKey].update !==  undefined &&
                                        elem[objectKey].update.model !==  undefined 
                                    ) {
                                        parentElement.update = elem[objectKey].update
                                    }
                                } else if (
                                    !_.isUndefined(elem[objectKey].update.model)
                                    && !_.isUndefined(dataSource[objectKey].update.model)
                                    && dataSource[objectKey].update.model === elem[objectKey].update.model
                                    && dataSource[objectKey].update.model.indexOf("ClientVisitTable") === -1 // МЕГА СУПЕР ДУПЕР КОСТЫЛЬ =( •̀д•́)
                                ) {
                                    elem[objectKey] = dataSource[objectKey];
                                    beenChanged = true;
                                    // Костыль #2
                                    if (
                                        parentElement &&
                                        parentElement.update !== undefined &&
                                        parentElement.update.model !== undefined &&
                                        elem[objectKey].update !==  undefined &&
                                        elem[objectKey].update.model !==  undefined 
                                    ) {
                                        parentElement.update = elem[objectKey].update
                                    }
                                }
                            } else {
                                elem[objectKey] = dataSource[objectKey];
                                beenChanged = true;
                            }
                        }
                    });

                    if (!beenChanged) {
                        notification.error({
                            message: 'в текущем state и его потомках не найден компонент "' + objectKey + '"',
                            description: 'Проверяйте код',
                            duration: 3000,
                        });
                    }
                } else {
                    notification.error({
                        message: 'В state нет компонента "' + objectKey + '"',
                        description: 'Есть только ' + Object.keys(data[key].data),
                        duration: 3000,
                    });
                }
            }
        });
    }

    if (Array.isArray(response.update) && data.length > 0) {
        response.update.forEach(dataSource => {
            updateingObject(dataSource)
        });
    } else updateingObject(response.update)


    let keyPid = findById(data, data[key].pid);
    if (keyPid !== -1) {
        data[keyPid].update = true;
        if (data[key].guiType !== "draw") data[keyPid].loading = true;
    }
    return data;
}

const fnRedrawing = (dispatch, payload, storage) => {
    const {token} = storage;
    let result = true;

    postGetData(token, payload.action, (response, successfully) => {
        console.log(response);
        if (!successfully) {
            if (isFunction(payload.callback)) {
                payload.callback(false);
            }
        } else if (!_.isUndefined(response) && !_.isNull(response)) {
            if (response.file) redrawingFile(token, response);
            if (response.message) {
                result = false;
                redrawingMessage(dispatch, payload, response)
            }
            if (response.replace || response.update || response.push || response.draw || response.close) {
                let data = Object.assign([], storage.data),
                    key = findById(data, payload.id);

                if (key !== -1) {
                    if (response.draw) {
                        data.push(containerDraw(data[key].id, response, storage.drawer, response.title));
                        dispatch.state.reloadData(data);
                    }
                    if (response.replace) {
                        if (response.title && response.title.trim() !== "") {
                            data[key].title = response.title;
                        }
                        data[key].data = response.replace;
                        dispatch.state.reloadData(data);
                    }
                    if (response.push) {
                        data[key].disabled = true;
                        data.push(container(data[key].id, response.push, response.title));
                        dispatch.state.reloadData(data);
                    }
                    if (response.update) {
                        data = redrawingUpdate(response, key, data);
                        dispatch.state.reloadData(data);
                    }
                    if (response.close) {
                        let keyPid = findById(data, data[key].pid);
                        if (keyPid !== -1) {
                            data[keyPid].update = true;
                            data[keyPid].loading = true;
                            dispatch.state.reloadData(data);
                        }
                        dispatch.state.destroy(data[key].id);
                    }

                } else {
                    console.error('В storage нет объекта с id = ' + payload.id);
                }
            }
            if (!response.message && payload.callback && typeof payload.callback !== 'object') {
                payload.callback(result);
            }
        }
    })
}
export default fnRedrawing;
