import {isMobile} from 'react-device-detect';
import {cloneObject} from "@Cyclope/core/store/extra";
import {ISocket} from "./index.d";

const initialState: ISocket = {
    unread: false,
    unreadnotes: false,
}

const socket = {
	state: initialState,
	reducers: {
		update(state: any, payload: any) {
            return {...state, ...payload}
        },
    },
    effects: (dispatch: any) => ({
		set(payload: any, rootState: any) {
            const data = cloneObject(rootState.socket);
            for (const [key, value] of Object.entries(initialState)) {
                if (payload[key]) data[key] = payload[key];
            }
            dispatch.socket.update(data)
        },
    })
}

export default socket;
