const _ = require('lodash');

// Функция проверки что это функция
export function isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
}
// Функция срабатывает при Change в тэгах
export function handleChange(design) {
    const {options, getFieldsValue, redrawing} = this.props;
    if (
        !_.isNull(options.change) &&
        !_.isUndefined(options.change)
    ) {
        if (!_.isUndefined(getFieldsValue) && !_.isUndefined(redrawing)) {
            // Задержка чтобы данные обновились
            setTimeout(function () {
                const values = getFieldsValue();
                let action = options.change;
                action.data = Object.assign({}, action.data, value_modification(values));
                redrawing(action);
            }, 100)
        } else if (_.isUndefined(getFieldsValue)) {
            console.warn('Не найдена функция getFieldsValue в объекте', this);
        } else if (_.isUndefined(redrawing)) {
            console.warn('Не найдена функция redrawing в объекте', this);
        }
    }
}
// Получить сласс у переменной
export function get_class(obj){
    function get_class(obj){
        return "".concat(obj).replace(/^.*function\s+([^\s]*|[^\(]*)\([^\x00]+$/, "$1") || "anonymous";
    };
    var result = "";
        if(obj === null)
            result = "null";
        else if(obj === undefined)
            result = "undefined";
    else {
        result = get_class(obj.constructor);
        if(result === "Object" && obj.constructor.prototype) {
            for(result in this) {
                if(typeof(this[result]) === "function" && obj instanceof this[result]) {
                    result = get_class(this[result]);
                    break;
                }
            }
        }
    };
    return result;
};
// Модификатор перед отправкой форм
export function value_modification(values) {
    var map = {};
    Object.keys(values).map(function (key) {
        map[key] = values[key];
        if (get_class(values[key]) == "Moment" || (values[key] && values[key]._isAMomentObject)) {
            if (values[key]._f) map[key] = values[key].local().format(values[key]._f);
            else map[key] = values[key].local().format('YYYY-MM-DD HH:mm:ss');
        }
    });
    return map;
}
// Клонируем объект
export function clone_object(ovject) {
    var clone = JSON.stringify(ovject);
    return JSON.parse(clone);
};
// Получить meta
export function getMeta(metaName) {
    const metas = document.getElementsByTagName('meta');

    for (let i = 0; i < metas.length; i++) {
        if (metas[i].getAttribute('name') === metaName) {
            return metas[i].getAttribute('content');
        }
    }

    return '';
}
// Получить расширение файла
export function get_fromat_file(filename) {
    if (!_.isUndefined(filename) && !_.isNull(filename)) {
        return filename.split('.').pop();
    }
    return null;
}

export function is_dark(color) {
    var color = color.substring(1);      // strip #
    var rgb = parseInt(color, 16);   // convert rrggbb to decimal
    var r = (rgb >> 16) & 0xff;  // extract red
    var g = (rgb >>  8) & 0xff;  // extract green
    var b = (rgb >>  0) & 0xff;  // extract blue
    var luma = 0.2126 * r + 0.7152 * g + 0.0722 * b; // per ITU-R BT.709
    return (luma < 40);
}
