import React, { Component } from "react";
import ReactDOM from "react-dom";
import {Provider} from "react-redux";
import {loadProgressBar} from "axios-progress-bar"

import {ConfigProvider} from "antd";
import ruRU from "antd/lib/locale-provider/ru_RU";

import store from "../core/store";
import LayoutController from "@Cyclope/manufacturing/screens";

class App extends Component {
    render() {
        loadProgressBar();
        return (
            <ConfigProvider locale={ruRU}>
                <Provider store={store}>
                    <LayoutController />
                </Provider>
            </ConfigProvider>
        );
    }
}

export default App;
