import DB from "@Cyclope/core/store/db";

const fnInitApp = (dispatch) => {
    const dbRetrieve = DB.retrieve(":auth");
    dbRetrieve.then(response => {
        if (response && response.token && response.menu && response.userName) {
            dispatch.state.loginReceived(response).then(() => {
                if (response.initialRoute) dispatch.state.mainRedrawing(response.initialRoute);
                dispatch.setting.initAuth({})
            })
        }
    });
}
export default fnInitApp;
